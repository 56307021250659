const APIURL = process.env.REACT_APP_BASE_API;
function apiHelper(apiType, path, data) {
  if (apiType == "post") {
    return new Promise(function (resolve, reject) {
      fetch(APIURL + path, {
        method: apiType,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  } else {
    return new Promise(function (resolve, reject) {
      fetch(APIURL + path, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  }
}

function apiMultipart(apiType, path, data) {
  return new Promise(function (resolve, reject) {
    fetch(APIURL + path, {
      method: apiType,
      headers: {},
      body: data,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(false);
        }
      );
  });
}

function apiMultipart_Token(apiType, path, data) {
  return new Promise(function (resolve, reject) {
    fetch(APIURL + path, {
      method: apiType,
      body: data,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(false);
        }
      );
  });
}

function apiHelperOpen(apiType, path, data) {
  if (apiType == "post") {
    return new Promise(function (resolve, reject) {
      fetch(path, {
        method: apiType,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  } else {
    return new Promise(function (resolve, reject) {
      fetch(path, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(false);
          }
        );
    });
  }
}

export { apiHelperOpen, apiHelper, apiMultipart, apiMultipart_Token };
