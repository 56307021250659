import { useEffect, useState } from "react";
import PageHeader from "../components/common/pageHeader";
import { List } from "../components/List";
import validate from "../utils/authFormValidation";
import { apiMultipart } from "../utils/ApiHelper";
import Toast from "../components/toats";

export const Careers = () => {
  const initialFormData = {
    description: "",
    file: "",
  };

  const [validationErrors, setValidationErrors] = useState({});
  const [form, updateForm] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function updateFormHandler(name, value) {
    updateForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function inputChangedHandler(event) {
    // Get name of changed input, and its corresponding value
    const { name, value } = event.target;

    // Update form state against the target input field
    updateFormHandler(name, value);
  }

  async function submitContact(e) {
    const errors = validate(form);

    if (Object.keys(errors).length > 0) {
      setValidationErrors({ ...errors });
      return;
    } else {
      setValidationErrors({});
    }

    const formData = new FormData();
    formData.append("avatar", form.file[0], form.file[0].name);
    formData.append("description", form.description);

    var result = await apiMultipart(
      "post",
      "/api/auth/uploadResumeAim",
      formData
    );
    setIsLoading(false);
    if (result.success) {
      Toast.fire({
        icon: "success",
        title: "Message submitted successfully!",
      });
    } else {
      Toast.fire({
        icon: "error",
        title: result.message,
      });
    }
  }

  const onChangeImages = async (e, key) => {
    if (!e.target.files || !e.target.files.length) return;
    const files = e.target.files;
    let _objFiles = files[0];
    if (_objFiles.size > 10000000000000000) {
      Toast.fire({
        icon: "error",
        title: "File too Big, please select a file less than 1MB !",
      });
      return;
    }
    updateFormHandler("file", files);
  };

  return (
    <>
      <PageHeader title="Careers" />
      <div className="container">
        <div className="row">
          <div style={{ marginTop: "20px" }}>
            <h3 style={{ padding: "8px 10px" }}>Careers</h3>
            <p style={{ padding: "8px 10px" }}>
              At AIM Tech AI, we&#39;re always eager to meet passionate
              individuals with a flair for technology and an appetite for
              innovation. If you possess technical skills that you believe can
              enhance our team and help our clients bring their vision to life,
              we want to get to know you.
            </p>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3 style={{ padding: "8px 10px" }}>How to express interest</h3>
            <List
              data={[
                "Attach your updated resume highlighting your relevant experience.",
                "Include a brief message detailing your expertise and why you're interested in joining us.",
                "Send it our way and let's explore the potential of working together.",
              ]}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <p style={{ padding: "8px 10px" }}>
              By joining our team, you'll have the opportunity to be at the
              forefront of technological advancements, collaborate with seasoned
              professionals, and make a tangible impact in the industry. We're
              excited to potentially welcome you aboard!
            </p>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <p
              style={{
                padding: "8px 10px",
                fontWeight: "bold",
                marginBottom: "0",
              }}
            >
              Contact: Info@aimtechai.com
            </p>
          </div>
          <div style={{ marginTop: "0", marginBottom: "20px" }}>
            <textarea
              placeholder="Write a brief messages"
              className="form-control"
              name="description"
              value={form.description}
              onChange={inputChangedHandler}
              id="description"
              style={{
                fontFamily: "Arial",
                fontSize: 14,
                width: "100%",
                "max-width": "500px",
                borderRadius: "6px",
                minHeight: "200px",
                padding: "10px",
              }}
            />
            <span className="errors">{validationErrors?.description}</span>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "40px" }}>
            <span
              style={{
                padding: "8px 10px",
                fontWeight: "bold",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            >
              Attach Resume
            </span>
            <br />
            <br />
            <br />
            <input
              type="file"
              name="upload"
              accept="application/pdf"
              onChange={(e) => onChangeImages(e)}
            />
            <br />
            <span className="errors">{validationErrors?.file}</span>
          </div>
          <div
            style={{ marginTop: "20px", marginBottom: "40px" }}
            onClick={() => submitContact()}
          >
            <span
              style={{
                padding: "8px 10px",
                fontWeight: "bold",
                border: "1px solid transparent",
                borderRadius: "8px",
                backgroundColor: "rgba(19, 31, 55, 0.9)",
                color: "white",
              }}
            >
              Submit
            </span>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "40px" }}>
            <p style={{ padding: "8px 10px", fontWeight: "bold" }}></p>
          </div>
        </div>
      </div>
    </>
  );
};
